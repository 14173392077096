<template>
  <div>
    <section class="section section-white"
             style="margin-bottom: 40px">

      <errors ref="resultsErrors"/>

      <br/>

      <div id="searchButtonsWrapper">
        <a @click="() => {  $emit('flow', -1)
                            _paq.push(['trackEvent', 'Flow', 'Avis', 'Modifier'])}"
           class="bouton"
           v-show="visibility.modifier">
          <font-awesome-icon icon="chevron-left"/>
          Modifier
        </a>

        <a :href="env.frontPath"
           @click="_paq.push(['trackEvent', 'Flow', 'Avis', 'Nouvel'])"
           class="bouton">
          <font-awesome-icon icon="search"/>
          Nouvelle recherche
        </a>
      </div>

      <div id="actionButtonsWrapper">
        <input :value="env.siteURL +'#/' + avis.summary.codeUrl"
               id="copyInput"
               style="position: absolute; left: -1000px; top: -1000px;"/>

        <a @click="copyLink"
           class="bouton "
           id="copyLink">
          <font-awesome-icon icon="copy"/>
          Partager le résultat
        </a>
      </div>

      <div class="container bordered"
           id="summary_leaflet_wrapper">
        <div id="summary">
          <div style="margin-bottom: 20px"><span class="title">Parcelle(s) </span></div>
          <span class="rightAlign">Adresse&nbsp;: </span><b><span v-if="avis.summary.adresse">{{
            avis.summary.adresse
          }}, <br/>
                    <span class="rightAlign"/>{{ avis.summary.commune.codePostal }} {{
            avis.summary.commune.nomCommune
          }}</span>
          <span v-else-if="avis.summary.commune">{{
              avis.summary.commune.codePostal
            }},{{ avis.summary.commune.nomCommune }}</span><span v-else><i>n/a</i></span></b><br/>
          <span class="rightAlign">Code parcelle&nbsp;: </span><b>
          <span v-if="avis.summary.codeParcelle && avis.summary.codeParcelle !== ''">{{
              avis.summary.codeParcelle
            }}</span><span v-else><i>n/a</i></span></b><br/>
          <br>
        </div>
        <div id="leaflet">
          <leaflet :max-zoom-center="leaflet.center"
                   :data="leaflet.data"/>
        </div>
      </div>

    </section>

    <section class="section section-grey v-flex"
             v-if="hasRisquesInformationObligatoire">

      <span class="title">A l'adresse saisie, les risques existants et faisant l'objet d'une obligation d'information au titre de l'IAL sont :</span>

      <template v-for="(plan, index) in avis.ppr">
        <risque :description=getDescriptionPPR(plan)
                :parcelle="leaflet.data.parcelles"
                :max-zoom-center="leaflet.center"
                :leaflet-wms-servers="(!plan.existsInGeorisque && !plan.existsInGpu) ? null  : (plan.existsInGeorisque ? conf.config.couchesRisques.ppr_georisques.serveurs : conf.config.couchesRisques.ppr_gpu.serveurs)"
                :leaflet-wms-layer="(!plan.existsInGeorisque && !plan.existsInGpu) ? null : (plan.existsInGeorisque ? conf.config.couchesRisques.ppr_georisques.layer : conf.config.couchesRisques.ppr_gpu.layer)"
                :leaflet-id-ppr="(!plan.existsInGeorisque && !plan.existsInGpu) ? null : (plan.existsInGeorisque ? plan.idGaspar : plan.idAssietteErrial)"
                :leaflet-attribution="conf.config.attributions.PPR"
                :leaflet-data="[{ data : plan.assiettes,
                                        color : '#840505', opacity: 0}]"
                :legend-blocks="[['#840505', 'Zone à risque entrainant une servitude d\'utilité publique']]"
                :logo-u-r-l="env.backPath + '/pictogrammes_risque/'+ getLogoRisque(plan.alea.familleAlea.aleaNiv1.length === 1? plan.alea.familleAlea.aleaNiv1[0].code : 'ic_multirisque_bleu') +'.png'"
                :title="(plan.alea.familleAlea.aleaNiv1.length === 1? plan.alea.familleAlea.aleaNiv1[0].libelle : 'Multirisques' )"
                v-bind:key="'plan_' + index"
                v-if="(!plan.existsCarte && !plan.existsInGpu && !plan.existsInGeorisque) || (plan.existsInGpu || plan.existsInGeorisque)
                                            || (plan.datePrescription != null  || plan.dateApprobation != null
                                            || plan.dateApplicationAnticipee != null ||  plan.dateProrogation != null)"
        />
      </template>


      <risque
          :detail="`
                        <p>Votre terrain se situe dans une zone concernée par les obligations légales de débroussaillement (OLD). Il s’agit de débroussailler, à l’intérieur de ce zonage :</p>
                        ` + (this.hasZoneUrbaine ? '- l’ensemble de votre terrain ;<br/>' : '')
                        + `- les abords des constructions sur une profondeur minimale de 50 mètres ;<br/>
                        - les voies privées sur une profondeur maximale de 10 mètres de part et d’autre de la voie ;<br/>
                        <p>Pour en savoir plus, vous pouvez consulter la <a href='https://files.georisques.fr/ial/Fiche_OLD.pdf' target='_blank'>fiche informative</a>, la <a href='https://www.georisques.gouv.fr/me-preparer-me-proteger/OLD-obligations-legales-de-debroussaillement' target='_blank'>page dédiée sur Géorisques</a>, le site <a href='https://www.ecologie.gouv.fr/feux-foret-vegetation-ayons-bons-reflexes/debroussailler-prevenir-feux-foret' target='_blank'>jedebroussaille.gouv.fr</a> et <a href='https://www.onf.fr/vivre-la-foret/+/1525::foire-aux-questions-faq-les-obligations-legales-de-debroussaillement-old.html' target='_blank'>le site de l'ONF</a>.</p>
                      `"
          :leaflet-wms-servers="conf.config.couchesRisques.old.serveurs"
          :leaflet-wms-layer="conf.config.couchesRisques.old.layer"
          :leaflet-attribution="conf.config.attributions.OLD"
          :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_old_bleu.png'"
          :title="'Obligation Légale de Débroussaillement'"
          :parcelle="leaflet.data.parcelles"
          :leaflet-data="[{ data : avis.summary.commune.multiPolygon, opacity: 0}]"
          :leaflet-min-zoom="15"
          :max-zoom-center="leaflet.center"
          :legend-blocks="[['#fcb1d2', 'Zonage informatif des obligations légales de débroussaillement']]"
          v-if="hasOLD"/>

      <risque :description="'Un tremblement de terre ou séisme, est un ensemble de secousses et de déformations brusques de l\'écorce terrestre (surface de la Terre). Le zonage sismique détermine l’importance de l’exposition au risque sismique.<br/>'+
                                  '<a href=\'#recommendations_sismicite\'>Lire les recommandations</a>'"
              :leaflet-data="typeof avis.summary.commune.communesLimitrophes.map ===  'function' ?
                                   [{ data : avis.summary.commune.codeZoneSismicite === '1' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#D8D8D8', opacity: 0},
                                    { data : avis.summary.commune.codeZoneSismicite === '2' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.summary.commune.codeZoneSismicite === '3' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.summary.commune.codeZoneSismicite === '4' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#E02B17', opacity: 0},
                                    { data : avis.summary.commune.codeZoneSismicite === '5' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#840505', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.codeZoneSismicite === '1').map(x => x.multiPolygon),
                                      color : '#D8D8D8', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.codeZoneSismicite === '2').map(x => x.multiPolygon),
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.codeZoneSismicite === '3').map(x => x.multiPolygon),
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.codeZoneSismicite === '4').map(x => x.multiPolygon),
                                      color : '#E02B17', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.codeZoneSismicite === '5').map(x => x.multiPolygon),
                                      color : '#840505', opacity: 0}] :
                                    undefined"
              :leaflet-min-zoom="14"
              :legend-blocks="[
                        ['#D8D8D8', '1 - très faible'],
                        ['#FFD332', '2 - faible'],
                        ['#FF8000', '3 - modéré'],
                        ['#E02B17', '4 - moyen'],
                        ['#840505', '5 - fort']]"
              :level="avis.summary.commune.codeZoneSismicite + ''"
              :level-max="'5'"
              :leaflet-wms-servers="conf.config.couchesRisques.sismicite.serveurs"
              :leaflet-wms-layer="conf.config.couchesRisques.sismicite.layer"
              :leaflet-codes-communes="avis.codesCommunes"
              :leaflet-attribution="conf.config.attributions.SISMICITE"
              :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_seisme_bleu.png'"
              :title="'Sismicité'"
              :parcelle="leaflet.data.parcelles"
              :max-zoom-center="leaflet.center"
              v-if="hasSismicite"/>


      <risque :description="'Le radon est un gaz radioactif naturel inodore, incolore et inerte. Ce gaz est présent partout dans les sols et il s’accumule dans les espaces clos, notamment dans les bâtiments.<br/>'+
                                  '<a href=\'#recommendations_radon\'>Lire les recommandations</a>'"
              :leaflet-data="typeof avis.summary.commune.communesLimitrophes.map ===  'function' ?
                                   [{ data : avis.summary.commune.classePotentielRadon === '1' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.summary.commune.classePotentielRadon === '2' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.summary.commune.classePotentielRadon === '3' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#840505', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.classePotentielRadon === '1').map(x => x.multiPolygon),
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.classePotentielRadon === '2').map(x => x.multiPolygon),
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.classePotentielRadon === '3').map(x => x.multiPolygon),
                                      color : '#840505', opacity: 0}] :
                                    undefined"
              :leaflet-min-zoom="14"
              :leaflet-wms-servers="conf.config.couchesRisques.radon.serveurs"
              :leaflet-wms-layer="conf.config.couchesRisques.radon.layer"
              :leaflet-attribution="conf.config.attributions.RADON"
              :leaflet-codes-communes="avis.codesCommunes"
              :legend-blocks="[
                        ['#FFD332', '1 : potentiel radon faible'],
                        ['#FF8000', '2 : potentiel radon moyen'],
                        ['#840505', '3 : potentiel radon significatif']]"
              :level="avis.summary.commune.classePotentielRadon + ''"
              :level-max="'3'"
              :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_rn_bleu.png'"
              :title="'Radon'"
              :parcelle="leaflet.data.parcelles"
              :max-zoom-center="leaflet.center"
              v-if="hasRadonHaut"/>

      <risque :parcelle="leaflet.data.parcelles"
              :max-zoom-center="leaflet.center"
              :description="'<p>Les pollutions des sols peuvent présenter un risque sanitaire lors des changements d’usage des sols (travaux, aménagements changement d’affectation des terrains) si elles ne sont pas prises en compte dans le cadre du projet.</p>'"
              :detail="(avis.installationClasseeParcelle.numberOf > 0 ? '- La parcelle a accueilli une ou plusieurs installation(s) classée(s) pour la protection de l’environnement soumise(s) à autorisation ou à enregistrement. Cette activité a pu provoquer des pollutions, notamment des sols des eaux souterraines ou des eaux superficielles.</br>Installation(s) concernée(s)  : <br/>' + getLibelleInstallationsclassees : '') +
                             (avis.sisParcelle.numberOf > 0 ? '- La parcelle est située en <a href=\'' + avis.sisParcelle.liste[0].ficheRisque + '\' target=\'_blank\' rel=\'noopener\'>secteur d’information sur les sols</a>.</br>' : '') +
                             (false ? '- La parcelle est affectée d’une servitude d’utilité publique au titre des installations classées au titre de l’article L515-12 du code de l’environnement.' : '') +
                             '<p><a href=\'#recommendations_pollution\'>Lire les recommandations</a></p>'"
              :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_basias_bleu.png'"
              :title="'Pollution des sols'"
              v-if="hasPollutionPrincipale"/>

      <risque
          :description="'La parcelle est concernée par un plan d’exposition au bruit car elle est exposée aux nuisances d’un aéroport.'"
          :parcelle="leaflet.data.parcelles"
          :max-zoom-center="leaflet.center"
          :detail="(avis.zonePlanExpositionBruit === 'A' ? 'Le niveau d’exposition au bruit de la parcelle est très fort (zone A en rouge). La zone A est principalement inconstructible.' : '') +
                             (avis.zonePlanExpositionBruit === 'B' ? 'Le niveau d’exposition au bruit de la parcelle est fort (zone B en orange). La zone B est principalement inconstructible.' : '') +
                             (avis.zonePlanExpositionBruit === 'C' ? 'Le niveau d’exposition au bruit de la parcelle est modéré (zone C en jaune). Certaines constructions sont autorisées sous conditions et sous réserve de mesures d’isolation acoustique.' : '') +
                             (avis.zonePlanExpositionBruit === 'D' ? 'Le niveau d’exposition au bruit de la parcelle est faible (zone D en verte). Les nouveaux logements sont autorisés à condition qu’ils fassent l’objet d’une isolation phonique.' : '')"
          :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_bruit_bleu.png'"
          :leaflet-data="typeof avis.plansExpositionBruit.map ===  'function' ?
                                   [{ data : avis.plansExpositionBruit.filter(x => x.zone === 'D').map(x => x.multiPolygon),
                                      color : '#058E0C', opacity: 0},
                                    { data : avis.plansExpositionBruit.filter(x => x.zone === 'C').map(x => x.multiPolygon),
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.plansExpositionBruit.filter(x => x.zone === 'B').map(x => x.multiPolygon),
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.plansExpositionBruit.filter(x => x.zone === 'A').map(x => x.multiPolygon),
                                      color : '#840505', opacity: 0}] :
                                    undefined"
          :legend-blocks="[
                        ['#840505', 'A - très fort'],
                        ['#FF8000', 'B - fort'],
                        ['#FFD332', 'C - modéré'],
                        ['#058E0C', 'D - faible']]"
          :level="avis.zonePlanExpositionBruit"
          :leaflet-wms-servers="conf.config.couchesRisques.peb.serveurs"
          :leaflet-wms-layer="conf.config.couchesRisques.peb.layer"
          :leaflet-attribution="conf.config.attributions.PEB"
          :title="'Bruit'"
          v-if="hasPEB"/>

      <risque
          :description="'<span style=\'white-space: nowrap;\'>La commune de l’adresse </span> saisie fait partie des communes listées comme susceptibles d’être atteintes par le recul du trait de côte.'"
          :detail="' Pour en savoir plus, renseignez-vous auprès de votre commune.'"
          :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_mouvement_bleu.png'"
          :title="'Recul du Trait de Côte'"
          v-if="hasReculTraitCote"/>

      <div class="clearfix"/>
    </section>

    <section class="section">

      <span class="title">Rappel</span>

      <div class="recommandations_wrapper">

        <div id="why_erp"
             class="container bordered">
          <div>
            <p><b>Pourquoi l'état des risques est important ?</b></p><br/>
            <p>À chaque vente ou location d'un bien, le propriétaire est tenu d'informer l’acquéreur ou le locataire de
              son bien immobilier (bâti et non bâti) sur certains risques auxquels
              le
              bien immobilier est exposé. Cette obligation d'information a été créée par la loi du 30 juillet 2003.</p>
            <br/>
            <p>L'état des risques permet de faire un bilan des principaux risques pouvant affecter ce bien, afin de bien
              informer les parties prenantes et de mettre en œuvre les mesures de
              protection éventuelles.</p><br/>
            <p>L’état des risques est obligatoire à la première visite.</p><br>
            <p>Attention ! Le non respect de ces obligations peut entrainer une annulation du contrat ou une diminution
              du prix (réfaction).</p><br>
          </div>
        </div>

        <template v-if="hasPPR">
          <h4 id="recommendations_PPR">Plans de prévention des risques</h4>
          <p>Votre immeuble est situé dans le périmètre d’un plan de prévention des risques. Il peut être concerné par
            l’obligation de réaliser certains travaux. Pour le savoir vous devez
            consulter le PPR auprès de votre commune ou sur <a
                :href="checkAndGetPrefectureLink(avis.summary.commune.codeDepartement)">le site de votre préfecture.</a>
          </p>
          <!--                    <p v-if="hasPPRN">Si votre bien est concerné par une obligation de travaux, vous pouvez bénéficier d'une aide de l'État, dans le cadre du Fonds de prévention des risques naturels-->
          <!--                                      majeurs (FPRNM).</p>-->
          <!--                    <p>Pour plus de renseignements, contacter la direction départementale des territoires (DDT) de votre département ou votre Direction de l'environnement, de l'aménagement et du logement (DEAL), si vous êtes en Outre-mer.</p>-->
          <!--                    <p>Pour se préparer et connaître les bons réflexes en cas de survenance du risque, consulter le dossier d'information communal sur les risques majeurs (DICRIM) auprès de votre-->
          <!--                       commune.</p>-->
        </template>

        <template v-if="hasSismicite">
          <h4 id="recommendations_sismicite">Sismicité</h4>
          <p v-if="hasSismiciteMoyenne">
            Pour certains bâtiments de taille importante ou sensibles, des dispositions spécifiques à mettre en oeuvre
            s'appliquent lors de la construction.<br/>
            Pour connaitre les consignes à appliquer en cas de séisme, vous pouvez consulter le site :<br/>
            <a href="https://www.gouvernement.fr/risques/seisme"
               rel="noopener"
               target="_blank">Que faire en cas de séisme ?</a>
          </p>
          <p v-if="hasSismiciteHaute || hasSismiciteTresHaute">Pour le bâti neuf et pour certains travaux lourds sur le
            bâti existant, en fonction de la zone de sismicité et du type de
            construction, des dispositions spécifiques à mettre en
            oeuvre s'appliquent lors de la construction. </p>
          <template v-if="hasSismiciteHaute">
            <p>Pour connaitre les consignes à appliquer en cas de séisme, vous pouvez consulter le site :<br/>
              <a href="https://www.gouvernement.fr/risques/seisme"
                 rel="noopener"
                 target="_blank">Que faire en cas de séisme ?</a></p>
          </template>
          <template v-if="hasSismiciteTresHaute">
            <p>Consignes à suivre en cas de séisme :<br/>
              - s’informer : écouter la radio, les premières consignes étant données par Radio France ;<br/>
              - ne pas aller chercher les enfants à l’école.</p>
            <p>Rester où l’on est :</p>
            <p>- à l’intérieur : se mettre près d’un mur, une colonne porteuse ou sous des meubles solides, s’éloigner
              des fenêtres ;<br/>
              - à l’extérieur : ne pas rester sous des fils électriques ou sous ce qui peut s’effondrer (ponts,
              corniches, toitures…) ;<br/>
              - en voiture : s’arrêter et ne pas descendre avant la fin des secousses.</p>
            <p>Se protéger la tête avec les bras.</p>
            <p>Ne pas allumer de flamme.</p>
            <p>Pour plus de détails, vous pouvez consulter le site :<br/>
              <a href="https://www.gouvernement.fr/risques/seisme"
                 rel="noopener"
                 target="_blank">Que faire en cas de séisme ?</a></p>
          </template>
        </template>


        <template>
          <div id="recommandation_rappel">
            <h4>Recommandation</h4>
            <p>Pour faire face à un risque,il faut se préparer et connaître les bons réflexes.</p>
            <p>Consulter le dossier d'information communal sur les risques (DICRIM) sur le site internet de votre mairie
              et les bons conseils sur <a target="_blank" href="https://georisques.gouv.fr/me-preparer-me-proteger">georisques.gouv.fr/me-preparer-me-proteger</a>
            </p>

          </div>

        </template>


        <template v-if="hasRadonHaut">
          <h4 id="recommendations_radon">Radon</h4>
          <p>Le bien est situé dans une zone à potentiel radon significatif. En plus des bonnes pratiques de qualité de
            l'air (aérer quotidiennement le logement par ouverture des fenêtres au
            moins 10 minutes par jour, ne pas obstruer les systèmes de ventilation), il est donc fortement recommandé de
            procéder au mesurage du radon dans le bien afin de s'assurer que sa
            concentration est inférieure au niveau de référence fixé à 300 Bq/m3, et idéalement la plus basse
            raisonnablement possible. Il est conseillé de faire appel à des professionnels
            du bâtiment pour réaliser un diagnostic de la situation et vous aider à choisir les solutions les plus
            adaptées selon le type de logement et la mesure. Ces solutions peuvent
            être mises en œuvre progressivement en fonction des difficultés de réalisation ou de leur coût. À l’issue
            des travaux, vous devrez réaliser de nouvelles mesures de radon pour
            vérifier leur efficacité.</p>
          <p>Une fiche d'informations sur le radon, le risque associé, son mesurage, les solutions techniques et les
            recommandations à suivre en fonction des résultats du mesurage est
            disponible : </p>
          <p><a
              href="https://www.georisques.gouv.fr/sites/default/files/ial/2018-Fiche%20d_information_sur_le_risque_potentiel_radon_DHUP-DGS-DGPR_102018_v3.pdf"
              rel="noopener"
              target="_blank">Information sur le risque
            potentiel radon</a>.</p>
        </template>

        <template v-if="hasPollutionPrincipale">
          <h4 id="recommendations_pollution">Pollution des sols</h4>
          <p>En cas de vente ou de location, le propriétaire est tenu de communiquer les informations relatives aux
            pollutions des sols, à l'acquéreur ou au locataire.
            (Article
            <template v-if="avis.installationClasseeParcelle.numberOf > 0">L. 514-20 du Code de l’Environnement
            </template>
            <template v-if="avis.installationClasseeParcelle.numberOf > 0 && avis.sisParcelle.numberOf > 0"> et
            </template>
            <template v-if="avis.sisParcelle.numberOf > 0">Article L. 125-7 du Code de l’Environnement)</template>
            )
          </p>
          <p>En cas de changement d'usage du terrain (travaux, constructions, changement d'affectation du bien), le
            maître d'ouvrage doit faire appel à un bureau d'étude qui devra attester
            de la mise en oeuvre de mesures de gestion de la pollution des sols. Si elle est exigée lors d'un dépôt de
            permis de construire ou d'aménager (Article L.556-1 du Code de
            l'Environnement), l'attestation devra être délivrée par un bureau d'étude certifié. Pour vous accompagner
            dans vos démarches, une liste de bureaux d’études
            certifiés dans le domaine des sols pollués est consultable à l'aide de ce lien :</p>
          <p><a @click="_paq.push(['trackEvent', 'Flow', 'Avis', 'Bureau_Etude'])"
                href='https://www.lne.fr/recherche-certificats/search/222'
                rel="noopener"
                target="_blank"
                style="float: none; text-align: center">Accéder à la liste des bureaux d’études certifiés</a></p>
        </template>
      </div>

      <div class="clearfix"/>
    </section>

    <!--        <section class="section v-flex">-->

    <!--            <span class="title">{{ hasRisquesInformationObligatoire || hasRisquesInformationNonObligatoire ? "Risques concernés par l’IAL mais non existants pour l’adresse saisie" : "Informations" }}</span>-->

    <!--            <risque :description="'<br/>Il n’y a pas de plan de prévention des risques (PPR) recensé sur les risques naturels.'"-->
    <!--                    :title="'Risques naturels'"-->
    <!--                    :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_inondation_bleu.png'"-->
    <!--                    style="width: calc(33% - 35px);"-->
    <!--                    v-if="!hasPPRN"/>-->

    <!--            <risque :description="'<br/>Il n’y a pas de plan de prévention des risques (PPR) recensé sur les risques miniers.'"-->
    <!--                    :title="'Risques miniers'"-->
    <!--                    :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_terre_bleu.png'"-->
    <!--                    style="width: calc(33% - 35px);"-->
    <!--                    v-if="!hasPPRM"/>-->

    <!--            <risque :description="'<br/>Il n’y a pas de plan de prévention des risques (PPR) recensé sur les risques technologiques.'"-->
    <!--                    :title="'Risques technologiques'"-->
    <!--                    :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_industrie_bleu.png'"-->
    <!--                    style="width: calc(33% - 35px);"-->
    <!--                    v-if="!hasPPRT"/>-->

    <!--            <risque :description="'<p>Votre parcelle n\'est pas située sur un secteur d\'information sur les sols.</p>' +-->
    <!--                                      '<p>Aucune installation classée pour la protection de l\'environnement soumise à autorisation ou enregistrement sur votre parcelle ne figure dans la base de données des installations classées.</p>'"-->
    <!--                    :title="'Pollution des sols'"-->
    <!--                    :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_basias_bleu.png'"-->
    <!--                    v-if="!hasPollutionPrincipale"/>-->

    <!--            <risque :description="'La parcelle n’est pas concernée par un plan d’exposition au bruit d’un aéroport.'"-->
    <!--                    :title="'Bruit'"-->
    <!--                    :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_bruit_bleu.png'"-->
    <!--                    v-if="!hasPEB"/>-->

    <!--            <div class="clearfix"/>-->

    <!--        </section>-->

    <section class="section v-flex"
             v-if="hasRisquesInformationNonObligatoire">

      <span class="title"> A l’adresse saisie, les risques suivants existent mais ne font pas l’objet d’une obligation d’information au titre de l’IAL</span>

      <template v-for="(plan, index) in avis.ppr">
        <risque :description=getDescriptionPPR(plan)
                :parcelle="leaflet.data.parcelles"
                :max-zoom-center="leaflet.center"
                :leaflet-wms-servers="(!plan.existsInGeorisque && !plan.existsInGpu) ? null  : (plan.existsInGeorisque ? conf.config.couchesRisques.ppr_georisques.serveurs : conf.config.couchesRisques.ppr_gpu.serveurs)"
                :leaflet-wms-layer="(!plan.existsInGeorisque && !plan.existsInGpu) ? null : (plan.existsInGeorisque ? conf.config.couchesRisques.ppr_georisques.layer : conf.config.couchesRisques.ppr_gpu.layer)"
                :leaflet-id-ppr="(!plan.existsInGeorisque && !plan.existsInGpu) ? null : (plan.existsInGeorisque ? plan.idGaspar : plan.idAssietteErrial)"
                :leaflet-attribution="conf.config.attributions.PPR"
                :leaflet-data="[{ data : plan.assiettes,
                                        color : '#840505', opacity: 0}]"
                :legend-blocks="[['#840505', 'Zone à risque entrainant une servitude d\'utilité publique']]"
                :logo-u-r-l="env.backPath + '/pictogrammes_risque/'+ getLogoRisque(plan.alea.familleAlea.aleaNiv1.length === 1? plan.alea.familleAlea.aleaNiv1[0].code : 'ic_multirisque_bleu' ) +'.png'"
                :title="(plan.alea.familleAlea.aleaNiv1.length === 1? plan.alea.familleAlea.aleaNiv1[0].libelle : 'Multirisques' )"
                v-bind:key="'plan_' + index"
                v-if="!((!plan.existsCarte && !plan.existsInGpu && !plan.existsInGeorisque) || (plan.existsInGpu || plan.existsInGeorisque)
                                            || (plan.datePrescription != null  || plan.dateApprobation != null
                                            || plan.dateApplicationAnticipee != null ||  plan.dateProrogation != null))"
        />
      </template>

      <risque
          :description="'Le radon est un gaz radioactif naturel inodore, incolore et inerte. Ce gaz est présent partout dans les sols et il s’accumule dans les espaces clos, notamment dans les bâtiments.'"
          :level="avis.potentielRadon + ''"
          :level-max="'3'"
          :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_rn_bleu.png'"
          :title="'Radon'"
          :leaflet-data="typeof avis.summary.commune.communesLimitrophes.map ===  'function' ?
                                   [{ data : avis.summary.commune.classePotentielRadon === '1' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.summary.commune.classePotentielRadon === '2' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.summary.commune.classePotentielRadon === '3' ? [avis.summary.commune.multiPolygon] : [],
                                      color : '#840505', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.classePotentielRadon === '1').map(x => x.multiPolygon),
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.classePotentielRadon === '2').map(x => x.multiPolygon),
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.summary.commune.communesLimitrophes.filter(x => x.classePotentielRadon === '3').map(x => x.multiPolygon),
                                      color : '#840505', opacity: 0}] :
                                    undefined"
          :leaflet-wms-servers="conf.config.couchesRisques.radon.serveurs"
          :leaflet-wms-layer="conf.config.couchesRisques.radon.layer"
          :leaflet-attribution="conf.config.attributions.RADON"
          :leaflet-codes-communes="avis.codesCommunes"
          :leaflet-min-zoom="14"
          :legend-blocks="[
                        ['#FFD332', '1 : potentiel radon faible'],
                        ['#FF8000', '2 : potentiel radon moyen'],
                        ['#840505', '3 : potentiel radon significatif']]"
          :parcelle="leaflet.data.parcelles"
          :max-zoom-center="leaflet.center"
          v-if="hasRadonMoyen"/>

      <!-- TODO :  Le détail de ces données est consultable ici.-->

      <risque
          :description="'Les pollutions des sols doivent notamment être prises en compte dans les projets de changements d\'usage (travaux, constructions, changement d\'affectation du bien) pour préserver la sécurité, la santé ou la salubrité publiques et l\'environnement.'"
          :detail="'<p>Dans un rayon de 500 m autour de votre parcelle, sont identifiés :</br>'+
                              (avis.installationClasseeRayonParcelle.numberOf > 0 ? '- '+ avis.installationClasseeRayonParcelle.numberOf +' installation(s) classée(s) pour la protection de l\'environnement (ICPE) soumises à autorisation ou à enregistrement, installations qui peuvent présenter des dangers ou inconvénients du fait de leur activité.</br>' : '') +
                              (avis.basiasRayonParcelle.numberOf > 0 ? '- '+ avis.basiasRayonParcelle.numberOf +' site(s) référencé(s) dans l\'inventaire CASIAS des sites ayant accueilli par le passé une activité industrielle ou une activité de service qui a pu générer une pollution des sols.</br>' : '') +
                              (avis.basolRayonParcelle.numberOf > 0 ? '- '+ avis.basolRayonParcelle.numberOf +' site(s) pollué(s) ou potentiellement pollués (Basol - terrain pollué ou potentiellement pollué appelant une action des pouvoirs publics à titre curatif ou préventif</br>' : '') +
                              (avis.sisRayonParcelle.numberOf > 0 ? '- ' + avis.sisRayonParcelle.numberOf + ' site(s) pollué(s) placé(s) en secteur d\'information sur les sols (SIS)</br></p>' : '</p>') +
                              (!hasPollutionPrincipale && numberOfParcelleMatches > 0 ? '<p>' + numberOfParcelleMatches + ' site(s) présente(nt) une proximité forte avec votre parcelle. Dans le cas où vous souhaiteriez en savoir davantage, il est recommandé de faire réaliser une étude historique et, le cas échéant, des analyses de sols par un bureau d’étude spécialisé dans le domaine des sols pollués.</p>' : '') +
                              (hasPollutionCentroidCommune ? '<p>Les données disponibles mentionnent parfois la présence d\'anciennes activités qui sont localisées par défaut sur le centre géographique de la commune lorsqu\'une localisation précise n\'est pas disponible. La présente analyse n\'en tient donc pas compte.</p>' : '')"
          :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_basias_bleu.png'"
          :title="'Pollution des sols (500 m)'"
          :parcelle="leaflet.data.parcelles"
          :leaflet-attribution="conf.config.attributions.POLLUTION_NON_REG"
          :max-zoom-center="leaflet.center"
          :leaflet-data="[{ data : avis.installationClasseeRayonParcelle.liste.map(x => x.ewkt),
                                      color : '#8E0800'},
                                    { data : avis.basiasRayonParcelle.liste.map(x => x.ewkt),
                                      color : '#9E9E00'},
                                    { data : avis.basolRayonParcelle.liste.map(x => x.ewkt),
                                      color : '#925600'},
                                    { data: leaflet.data.sis,
                                      color: '#2A4999'}]"
          v-if="hasPollutionNonReglementaire"/>

      <risque :description="  'TRI : ' + avis.TRIs.libelle_tri +
                                    '<p>Un territoire à risque important d’inondation (TRI) est une zone où les enjeux potentiellement exposés aux inondations sont les plus importants.</p>'+
                                    'L’identifiant de votre TRI est : ' + avis.TRIs.identifiant_tri "
              :detail="'<p>Votre bien est situé sur une zone qualifiée à risques d’inondation couvrant les aléas suivants :</p>' +
                             '<p>' + getRisquesTri + '</p>'"
              :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_inondation_bleu.png'"
              :title="'Inondations'"
              :parcelle="leaflet.data.parcelles"
              :leaflet-wms-servers="conf.config.couchesRisques.tri.serveurs"
              :leaflet-wms-layer="conf.config.couchesRisques.tri.layer"
              :leaflet-attribution="conf.config.attributions.PPR"
              :leaflet-data="avis.bboxParcelles"
              :leaflet-tri="true"
              v-if="hasTRI && !hasPPRi"/>

      <risque :parcelle="leaflet.data.parcelles"
              :description="'Votre bien est situé à moins de ' + (avis.hasCentraleNucleaire ? '20 km' :  '10 km') + ' d’une installation nucléaire de base, installation dans laquelle une certaine quantité de substance ou de matière radioactives est présente (ex. réacteurs nucléaires de production d\'électricité (centrale nucléraire), installations de préparation, enrichissement, fabrication, traitement ou entreposage de combustibles nucléaires ; etc.)'"
              :detail="'<p>Ces installations sont contrôlées par l’Autorité de Sureté Nucléaire dont les rapports de contrôle sont consultables au lien suivant : <a href=\'https://www.asn.fr/Controler/Actualites-du-controle\' target=\'_blank\' rel=\'noopener\'>https://www.asn.fr/Controler/Actualites-du-controle.</a></p>' +
                                '<p>Installation(s) concernée(s)  : <br/>' + getLibelleInstallationsNucleaires + '</p>'"
              :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_nucleaires_bleu.png'"
              :title="'Installations nucléaires de base'"
              :max-zoom-center="leaflet.center"
              :leaflet-attribution="conf.config.attributions.INSTALLATIONS_NUCLEAIRES"
              :leaflet-data="[
                  {
                    data : avis.nucleaires.installations.map(x => x.ewkt),
                    color : '#8E0800'
                  }
              ]"
              v-if="avis.nucleaires.installations.length > 0"
      />

      <risque :parcelle="leaflet.data.parcelles"
              :max-zoom-center="leaflet.center"
              :description="'Les sols argileux évoluent en fonction de leur teneur en eau. De fortes variations d’eau (sécheresse ou d’apport massif d’eau) peuvent donc fragiliser progressivement les constructions (notamment les maisons individuelles aux fondations superficielles) suite à des gonflements et des tassements du sol, et entrainer des dégâts pouvant être importants. Le zonage \'argile\' identifie les zones exposées à ce phénomène de retrait-gonflement selon leur degré d’exposition.'"
              :detail="(avis.niveauArgile === 3 ? 'Exposition forte : La probabilité de survenue d’un sinistre est élevée et l’intensité des phénomènes attendus est forte. Les constructions, notamment les maisons individuelles, doivent être réalisées en suivant des prescriptions constructives ad hoc. Pour plus de détails</br><a href=\'https://www.cohesion-territoires.gouv.fr/sols-argileux-secheresse-et-construction#e3\' target=\'_blank\' rel=\'noopener\'>Sols argileux sécheresse et construction</a>' : '') +
                             (avis.niveauArgile === 2 ? 'Exposition moyenne : La probabilité de survenue d’un sinistre est moyenne, l’intensité attendue étant modérée.  Les constructions, notamment les maisons individuelles, doivent être réalisées en suivant des prescriptions constructives ad hoc. Pour plus de détails :</br><a href=\'https://www.cohesion-territoires.gouv.fr/sols-argileux-secheresse-et-construction#e3\' target=\'_blank\' rel=\'noopener\'>Sols argileux sécheresse et construction</a>' : '') +
                             (avis.niveauArgile === 1 ? 'Exposition faible : La survenance de sinistres est possible en cas de sécheresse importante, mais ces désordres ne toucheront qu’une faible proportion des bâtiments (en priorité ceux qui présentent des défauts de construction ou un contexte local défavorable, avec par exemple des arbres proches ou une hétérogénéité du sous-sol). Il est conseillé, notamment pour la construction d’une maison individuelle, de réaliser une étude de sols pour déterminer si des prescriptions constructives spécifiques sont nécessaires. Pour plus de détails :</br><a href=\'https://www.cohesion-territoires.gouv.fr/sols-argileux-secheresse-et-construction#e3\' target=\'_blank\' rel=\'noopener\'>Sols argileux sécheresse et construction</a>' : '') +
                             (avis.niveauArgile === 0 ? 'Exposition nulle : aucune présence de sols argileux n’a été identifiée selon les cartes géologiques actuelles. Toutefois il peut y avoir des poches ponctuelles de sols argileux.' : '') "
              :leaflet-data="[{ data : avis.lentillesArgile.filter(x => x.niveauAlea === 1).map(x => x.multiPolygon),
                                      color : '#FFD332', opacity: 0},
                                    { data : avis.lentillesArgile.filter(x => x.niveauAlea === 2).map(x => x.multiPolygon),
                                      color : '#FF8000', opacity: 0},
                                    { data : avis.lentillesArgile.filter(x => x.niveauAlea === 3).map(x => x.multiPolygon),
                                      color : '#840505', opacity: 0}]"
              :leaflet-min-zoom="14"
              :legend-blocks="[
                        ['#FFD332', '1 : Exposition faible'],
                        ['#FF8000', '2 : Exposition moyenne'],
                        ['#840505', '3 : Exposition forte']]"
              :level="avis.niveauArgile + ''"
              :level-max="'3'"
              :leaflet-wms-servers="conf.config.couchesRisques.argile.serveurs"
              :leaflet-wms-layer="conf.config.couchesRisques.argile.layer"
              :leaflet-attribution="conf.config.attributions.ARGILE"
              :leaflet-bbox-risque="avis.bboxParcelles"
              :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_terre_bleu.png'"
              :title="'Argile'"
              v-if="hasArgile"/>
      <!-- https://wiki.openstreetmap.org/wiki/Zoom_levels 16 384 , 65 536 -->
      <risque :parcelle="leaflet.data.parcelles"
              :max-zoom-center="leaflet.center"
              :leaflet-max-zoom="14"
              :description="'Une canalisation de matières dangereuses (gaz naturel, produits pétroliers ou chimiques) est située dans un rayon de 500 m autour de votre parcelle. La carte représente les implantations présentes autour de votre localisation. Il convient de rechercher une information plus précise en se rendant en mairie.'"
              :leaflet-data="[{ data : avis.canalisations,
                                      color : '#2A4999', opacity: 0}]"
              :leaflet-wms-servers="conf.config.couchesRisques.canalisations.serveurs"
              :leaflet-wms-layer="conf.config.couchesRisques.canalisations.layer"
              :legend-blocks="[['#2A4999', 'Canalisations de transport de matières dangereuses']]"
              :leaflet-attribution="conf.config.attributions.CANALISATIONS"
              :logo-u-r-l="env.backPath + '/pictogrammes_risque/ic_reseaux_canalisation_bleu.png'"
              :title="'Canalisations transport de matières dangereuses'"
              v-if="hasCanalisations"/>

      <div class="clearfix"/>
    </section>
    <div id="bottomButtonsWrapper">
      <a @click="$emit('flow', 1)"
         class="bouton success"
         href='#bullet-progress-bar_wrapper'
      >
        Accéder à l’étape suivante pour compléter l’état des risques
        <font-awesome-icon class="end"
                           icon="chevron-right"/>
      </a><br/>
      <span v-if="avis.ppr.length > 0">Certains risques nécessitent de faire des travaux obligatoires. Il est nécessaire de compléter ces informations pour finaliser l'état des risques.</span>
    </div>

  </div>
</template>

<script>
import Errors from '../../../components/content/base/Errors'
import Leaflet from "../leaflet/LeafletResults";
import Risque from "../Risque";
import moment from "moment"
import mixinAvisHas from "../../mixins/avisHas";
import * as conf from "../../../config.js";
import prefectureLink from "../../../script/prefectureLink";

export default {
  name: 'SearchResults',
  mixins: [mixinAvisHas],
  components: {
    Risque,
    Leaflet,
    Errors
  },
  props: {
    leaflet: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    loading: false,
    rendered: false,
    mounted: false,
    visibility: {
      details: false,
      modifier: true
    },
    env: {
      frontPath: process.env.VUE_APP_FRONT_PATH,
      backPath: process.env.VUE_APP_BACK_STATIC_PATH,
      apiPath: process.env.VUE_APP_BACK_API_PATH,
      siteURL: "https://" + document.domain + "/"
    },
    tab: {
      concordances: {
        pollution: {
          vu: false
        },
        index: 'POLLUTION'
      }
    },
    conf: conf
  }),
  methods: {
    getDescriptionPPR(plan) {
      let description = 'Le ' + plan.alea.familleAlea.famillePPR.libelle + ' (PPR) de type ' +
          (plan.modeleProcedure ? ' ' + plan.modeleProcedure + ' ' : 'multirisques ') +
          (plan.libelleGaspar ? 'nommé ' + plan.libelleGaspar + ' ' : '');

      if ((plan.existsInGpu || plan.existsInGeorisque)) {
        description += 'a été ' + (plan.dateApprobation ? 'approuvé ' : '') +
            'et affecte votre bien.</br>' +
            (plan.datePrescription ? 'Date de prescription : ' + moment(plan.datePrescription).format('DD/MM/YYYY') + '</br>' : '') +
            (plan.dateApprobation ? 'Date d\'approbation : ' + moment(plan.dateApprobation).format('DD/MM/YYYY') + '</br>' : '') +
            '<p>' +
            (plan.dateApprobation ? 'Un PPR approuvé est un PPR définitivement adopté. ' : '') +
            '<p>' +
            'Un PPR prescrit est un PPR en cours d\'élaboration sur la commune dont le périmètre et les règles sont en cours d\'élaboration.Un PPR anticipé est un PPR non encore approuvé mais dont les règles sont déjà à appliquer, par anticipation.' +
            '<br/></p>';
      }
      if (plan.dateApplicationAnticipee != null && !plan.existsInGpu && !plan.existsInGeorisque) {
        description += 'a été ' + (plan.dateApplicationAnticipee ? 'approuvé ' : '') +
            'par anticipation et peut affecter votre bien.</br>' +

            (plan.datePrescription ? 'Date de prescription : ' + moment(plan.datePrescription).format('DD/MM/YYYY') + '</br>' : '') +
            (plan.dateApplicationAnticipee ? 'Date d\'approbation anticipée : ' + moment(plan.dateApplicationAnticipee).format('DD/MM/YYYY') + '</br>' : '') +
            '<p>' +
            (plan.dateApplicationAnticipee ? 'Un PPR approuvé par anticipation est un PPR visant les nouveaux immeubles et biens immobiliers et rendu immédiatement opposable par arrêté préfectoral.' : ' ') +
            '<p>' + 'vous pouvez trouvez des informations complémentaires notamment la zone de servitude d’utilité publique et le règlement auprès de votre préfecture.' +
            '<br/>' + '</p>'
      } else if (plan.datePrescription != null && !plan.existsInGpu && !plan.existsInGeorisque) {
        description += 'a été ' + (plan.datePrescription ? 'prescrit ' : '') +
            'et peut affecter votre bien.</br>' +
            (plan.datePrescription ? 'Date de prescription : ' + moment(plan.datePrescription).format('DD/MM/YYYY') + '</br>' : '') +
            '<p>' +
            ((plan.datePrescription != null) ? 'Un PPR prescrit est un PPR en cours d’élaboration à la suite d’un arrêté de prescription.' : '') +
            '<p>' +
            'Un PPR qui est en cours d’élaboration n’est pas applicable, mais il doit faire l’objet d’une information des potentiels futurs locataires ou des futurs acquéreurs au titre de l’information acquereur-locataires. vous pouvez trouvez des informations complémentaires notamment la zone d’étude auprès de votre préfecture.' +
            '<br/></p>';
      } else if (plan.dateProrogation != null && !plan.existsInGpu && !plan.existsInGeorisque) {
        description += 'a été ' + (plan.dateProrogation ? 'prescrit ' : '') +
            'et peut affecter votre bien.</br>' +
            (plan.dateProrogation ? 'Date de prorogation : ' + moment(plan.dateProrogation).format('DD/MM/YYYY') + '</br>' : '') +
            '<p>' +
            ((plan.dateProrogation != null) ? 'Un PPR prescrit est un PPR en cours d’élaboration à la suite d’un arrêté de prescription.' : '') +
            '<p>' +
            'Un PPR qui est en cours d’élaboration n’est pas applicable, mais il doit faire l’objet d’une information des potentiels futurs locataires ou des futurs acquéreurs au titre de l’information acquereur-locataires. vous pouvez trouvez des informations complémentaires notamment la zone d’étude auprès de votre préfecture.' +
            '<br/></p>';
      } else {
        description += ' '
      }
      description += '<p>Le PPR couvre les aléas suivants : </p> ' +
          (plan.alea.familleAlea.aleaNiv1.length === 1 ? this.getAleaNiv2(plan.alea) : this.getAleaMultirisques(plan.alea)) +
          '<p>' +
          (plan.alea.familleAlea.famillePPR.code === 'PPRN' ? 'Le plan de prévention des risques est un document réalisé par l’Etat qui interdit de construire dans les zones les plus exposées et encadre les constructions dans les autres zones exposées.<br/>' : '') +
          (plan.alea.familleAlea.famillePPR.code === 'PPRM' ? 'Le plan de prévention des risques est un document réalisé par l’Etat qui interdit de construire dans les zones les plus exposées et encadre les constructions dans les autres zones exposées.<br/>' : '') +
          (plan.alea.familleAlea.famillePPR.code === 'PPRT' ? 'Le plan de prévention des risques technologiques est un document réalisé par l’État qui a pour objectif de résoudre les situations difficiles en matière d’urbanisme héritées du passé et de mieux encadrer l’urbanisation future autour du site.<br/>' : '') +
          '</p><a href=\'#recommendations_PPR\'>Lire les recommandations</a>';
      return description;
    },
    getAleaNiv2(aleas) {
      let libelle = ''
      aleas.aleaNiv2.forEach((item) => {
        libelle += item.libelle + '<br/>';
      });
      return libelle
    },
    getAleaMultirisques(aleas) {
      let libelle = ''
      aleas.familleAlea.aleaNiv1.forEach((item) => {
        libelle += item.libelle + '<br/>';
        aleas.aleaNiv2.forEach((subItem) => {
          if (subItem.code.startsWith(item.code) && subItem.code.match(/[1-9]{3}/)) {
            libelle += '&nbsp;&nbsp;&nbsp;-' + subItem.libelle + '<br/>';
          }
        });
        libelle += '<br/>';
      });
      return libelle
    },
    checkAndGetPrefectureLink(value) {
      return prefectureLink(value)
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    copyLink() {
      this._paq.push(['trackEvent', 'Flow', 'Copy Link'])
      let copyInput = document.getElementById("copyInput")
      copyInput.select()
      document.execCommand("copy")
      alert("URL copiée : " + copyInput.value)
    },
    getLogoRisque(codeAlea) {

      switch (codeAlea) {
        case '11' :
          return 'ic_inondation_bleu'
        case '12' :
          return 'ic_mouvement_terrain_bleu'
        case '13' :
          return 'ic_seisme_bleu'
        case '14' :
          return 'ic_r_montagne_bleu'
        case '15' :
          return 'ic_volcan_bleu'
        case '16' :
          return 'ic_feu_foret_bleu'
        case '17' :
          return 'ic_meteo_bleu'
        case '18' :
          return 'ic_rn_bleu'
        case '21' :
          return 'ic_industrie_bleu'
        default :
          return 'ic_multirisque_bleu'
      }
    }
  },
  computed: {
    _paq: function () {
      return window._paq
    },
    getLibelleInstallationsclassees() {
      let libelle = ''

      for (let installation in this.avis.installationClasseeParcelle.liste) {
        installation = this.avis.installationClasseeParcelle.liste[installation]
        libelle += '- <a href="https://www.georisques.gouv.fr/risques/installations/donnees/details/' + installation.code + '" target="_blank" rel="noopener">' + installation.nom + '</a><br/>'
      }

      return libelle
    },
    getLibelleInstallationsNucleaires() {
      let libelle = ''

      for (let installation in this.avis.nucleaires.installations) {
        installation = this.avis.nucleaires.installations[installation]
        libelle += '- ' + installation.nomInstallation + ' (' + installation.libCommune + ')<br/>'
      }

      return libelle
    },
    getRisquesTri() {
      let libelle = ''

      for (let alea in this.avis.TRIs.aleas) {
        libelle += '- ' + this.avis.TRIs.aleas[alea] + '<br/>'
      }

      return libelle
    }
  },
  mounted() {
    console.log("this.avis", this.avis)
    this.mounted = true
    this.$nextTick(() => {
      // this.$refs.resultsErrors.sendSuccess('Participez à améliorer Kelrisks en répondant à ce court questionnaire (durée 3min)<a target=\'_blank\' style=\'display:inline-block; margin: 0 10px; min-width: 0; float: none;\' class=\'bouton\' href=\'https://docs.google.com/forms/d/e/1FAIpQLSd3tB_gWGZsucCihp4VYDqv0Vxq61nqnpQJeMkI17nY39St_w/viewform?usp=sf_link\'>Répondre</a>')
    })
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

#searchButtonsWrapper {
  float: left;
}

#searchButtonsWrapper a,
#actionButtonsWrapper a,
#bottomButtonsWrapper a {
  display: inline-block;
  float: none;
}

#bottomButtonsWrapper {
  flex: 0 0 100%;
  margin-top: 25px;
  text-align: center;
}

#actionButtonsWrapper {
  float: right;
}

@media (min-width: 630px) {
  #searchButtonsWrapper a:last-of-type,
  #actionButtonsWrapper a:last-of-type {
    margin-right: 0;
  }
}

@media (max-width: 1350px) {

  #searchButtonsWrapper {
    text-align: center;
    width: 100%;
  }

  #actionButtonsWrapper {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 630px) {
  #searchButtonsWrapper a,
  #actionButtonsWrapper a {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.container {
  max-width: unset;
}

#summary_leaflet_wrapper {
  display: flex;
  padding: 0;
  width: 100%;
}

#summary {
  float: left;
  padding: 20px;
  text-align: left;
  width: calc(50%);
}

#summary span {
  line-height: 25px;
}

#summary span.rightAlign {
  display: inline-block;
  padding-right: 5px;
  text-align: right;
  width: 150px;
}

#leaflet {
  float: left;
  height: 400px;
  padding: 0 !important;
  width: calc(50%);
}

@media (max-width: 1000px) {

  #summary_leaflet_wrapper {
    display: block;
  }

  #summary {
    clear: both;
    float: left;
    width: 100%;
  }

  #leaflet {
    clear: both;
    float: left;
    height: 210px;
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }
}

section.v-flex {
  display: flex;
  flex-wrap: wrap;
}

section.v-flex > span {
  flex: 0 0 100%;
  text-align: left;
}

.recommandations_wrapper {
  margin: auto;
  width: 60%;
}

.recommandations_wrapper h4 {
  margin-bottom: 0;
  margin-top: 20px;
}

.recommandations_wrapper p {
  margin-bottom: 0;
  margin-top: 0;
}

#why_erp {
  background-color: var(--theme-background-grey);
  border: 2px solid #BAB9B9;
  width: 100%;
}

#recommandation_rappel {
  margin-top: 20px;
  background-color: var(--theme-background-grey);
  border: 2px solid #BAB9B9;
  width: 100%;
  padding-top: -2px;
  padding: 20px;
}

#recommandation_rappel h4 {
  margin-top: 0px;
}


.container.bordered {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
  /*float            : left;*/
  padding: 20px;
}

sup {
  font-size: 0.6em;
}

.infobulle {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
  display: none;
  margin: 5px;
  padding: 5px;
  position: absolute;
  text-align: justify;
  /*position: sticky;*/
  width: calc(25% - 10px);
  z-index: 1;
}

.infobulle:hover {

  display: block;
}

</style>
